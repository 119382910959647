import React from "react"
import Main from "@layout/Main/Main"
import Layout from "@layout/Layout"
import Divider from "@components/Divider"
import { Box, Card, Flex, Text, Container, Heading } from "theme-ui"
import ContactForm from "@components/ContactForm"

import Helmet from "react-helmet"

export default function ContactSection(props) {
  return (
    <Layout>
      <React.Fragment>
        <Helmet>
          <script src="https://unpkg.com/@botpoison/browser"></script>
        </Helmet>
      </React.Fragment>
      <Box sx={{ mt: ["8rem", "10rem"], p: [3, 0] }}>
        <Box sx={{ maxWidth: "700px", m: "auto" }}>
          <Heading variant="h2" sx={{ fontWeight: "600", textAlign: "center" }}>
            Küldj üzenetet az alábbi ürlap segítségével
          </Heading>
          {/* <Text sx={{display: "block"}}>Árajánlat kéréshez adj meg minél több releváns információt, mint például a ...</Text> */}
        </Box>
        <Divider />
        <Card variant="paper" sx={{ mb: 4, maxWidth: "700px", m: "auto" }}>
          <ContactForm />
        </Card>
      </Box>
    </Layout>
  )
}
