import React from "react";
import { Container } from "theme-ui";

const Main = ({ children, ...props }) => (
  <Container variant="main" {...props}>
    {children}
  </Container>
);

export default Main;
