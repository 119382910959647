import React from "react"
import PropTypes from "prop-types"
import { Box, Label, Input, Textarea, Button, Spinner } from "theme-ui"

function ContactForm({ handleSubmit, submitting, success }) {
  return (
    <form
      data-botpoison-public-key="pk_755f34f9-2763-4327-96e0-7d847d497a67"
      className="w-full max-w-lg"
      name="contact v1"
      method="post"
      action="https://submit-form.com/BmxD0vlL"
      target="_self"
      onSubmit={handleSubmit}
    >
      <Box variant="forms.row">
        <Box variant="forms.column">
          <Label htmlFor="contact-form-name">Vezetéknév</Label>
          <Input
            sx={{ bg: "background" }}
            type="text"
            id="contact-form-name"
            name="first name"
            required
          />
        </Box>
        <Box variant="forms.column">
          <Label htmlFor="contact-form-company">Keresztnév</Label>
          <Input
            sx={{ bg: "background" }}
            type="text"
            id="contact-form-company"
            name="company"
          />
        </Box>
      </Box>
      <Box variant="forms.row">
        <Box variant="forms.column">
          <Label htmlFor="contact-form-email">E-mail cím</Label>
          <Input
            sx={{ bg: "background" }}
            type="email"
            id="contact-form-email"
            name="email"
            required
          />
        </Box>
        <Box variant="forms.column">
          <Label htmlFor="contact-form-phone">Telefonszám</Label>
          <Input
            sx={{ bg: "background" }}
            type="tel"
            id="contact-form-phone"
            name="phone"
          />
        </Box>
      </Box>
      <Box variant="forms.row">
        <Label htmlFor="contact-form-subject">Megkeresés tárgya</Label>
        <Input
          sx={{ bg: "background" }}
          type="text"
          id="contact-form-subject"
          name="subject"
          required
        />
        <Input
          type="hidden"
          name="_redirect"
          value={`http://vintagebikestore.com/thank-you`}
        />
      </Box>
      <Box variant="forms.row">
        <Label htmlFor="contact-form-message">Üzenet</Label>
        <Textarea
          sx={{ bg: "background" }}
          name="message"
          id="contact-form-message"
        />
      </Box>
      <Box>
        <Box
          sx={{
            m: [`auto`, `unset`],
            display: `block`,
            width: ["100%", "unset"],
            textAlign: ["center", "right"],
          }}
        >
          <Button
            sx={{
              px: 4,
            }}
            variant={success || submitting ? "disabled" : "primary"}
            disabled={success || submitting}
            type="submit"
            required
          >
            Küldés {submitting && <Spinner size="20" />}
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool,
}
